.rdp-composer-login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("./background.jpg");
  background-size: 110%;
}

.rdp-composer-login-sidebar {
  position: absolute;
  width: 400px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 0px 35px black;
}

.rdp-composer-login-sidebar * {
  font-family: Prompt-Bold;
}

.rdp-composer-login-sidebar-logo {
  background: url("./logo.png");
  width: 150px;
  height: 50px;
  background-size: 100%;
  display: block;
  margin-top: 40px;
  margin-left: 40px;
  margin-bottom: 40px;
}

.rdp-composer-login-sidebar-title {
  display: block;
  padding: 50px 40px;
  font-size: 30px;
}

.rdp-composer-login-sidebar-form {
  border-top: 1px solid #f0f0f0;
}

.rdp-composer-login-title {
  font-size: 17px;
  color: #515151;
  display: block;
  margin: 35px 40px;
  font-weight: bold;
  line-height: 21px;
}

.rdp-composer-login-sidebar-input {
  padding: 10px 40px;
  margin-top: 20px;
}

.rdp-composer-login-sidebar-input label {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  color: #764ab0;
}

.rdp-composer-login-sidebar-input input {
  display: block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

.rdp-composer-login-sidebar-submit {
  background: #764ab0;
  color: #fff;
  border: 0;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 20px 35px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.rdp-composer-login-sidebar-preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: #fff;
  background-image: url("./ico-preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-login-sidebar-languages {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 400px;
}

.rdp-composer-login-sidebar-languages-overlay {
  position: absolute;
  top: 130px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.94);
}

.rdp-composer-login-sidebar-languages-items {
  width: 280px;
  height: 100px;
  background: #FFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  position: absolute;
  top: 100px;
  left: 60px;
}

.rdp-composer-login-sidebar-languages-item {
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  padding-left: 15px;
  border-bottom: 1px solid #e4e4e4;
}

.rdp-composer-login-sidebar-languages-item:last-child {
  border-bottom: 0;
}

.rdp-composer-login-sidebar-languages-item-label {
  font-size: 14px;
  text-transform: uppercase;
  color: #2d2d2d;
}

.rdp-composer-login-sidebar-languages-item-icon {
  width: 20px;
  height: 48px;
  position: absolute;
  right: 15px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-login-sidebar-languages-item-icon.en {
  background-image: url("./icon-flag-united.png");
}

.rdp-composer-login-sidebar-languages-item-icon.pt_br {
  background-image: url("./icon-flag-brasil.png");
}
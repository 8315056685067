.rdp-composer-sidebar-subitem {
  width: 240px;
  height: 160px;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;
  background-color: #FFF;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  vertical-align: top;
}

.rdp-composer-sidebar-subitem-message {
  display: flex;
  opacity: 0;
  background: #fff;
  z-index: 1;
  height: 100%;
  width: 100%;
  white-space: normal;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px;
  font-weight: bold;
  line-height: 17px;
  border: 1px solid #e7e7e7;
  color: #7c7c7c;
  transition: 0.2s all linear;
}

.rdp-composer-sidebar-subitem:hover .rdp-composer-sidebar-subitem-message {
  opacity: 1;
}

.rdp-composer-sidebar-subitem.head-1 {
  background-image: url(./assets/head-1.png);
}

.rdp-composer-sidebar-subitem.text-1 {
  background-image: url(./assets/text-1.png);
}

.rdp-composer-sidebar-subitem.text-2 {
  background-image: url(./assets/text-2.png);
}

.rdp-composer-sidebar-subitem.text-3 {
  background-image: url(./assets/text-3.png);
}

.rdp-composer-sidebar-subitem.text-4 {
  background-image: url(./assets/text-4.png);
}

.rdp-composer-sidebar-subitem.text-5 {
  background-image: url(./assets/text-5.png);
}

.rdp-composer-sidebar-subitem.text-6 {
  background-image: url(./assets/text-6.png);
}

.rdp-composer-sidebar-subitem.text-7 {
  background-image: url(./assets/text-7.png);
}

.rdp-composer-sidebar-subitem.text-two-column {
  background-image: url(./assets/text-two-column.png);
}

.rdp-composer-sidebar-subitem.statement-1 {
  background-image: url(./assets/statement-1.png);
}

.rdp-composer-sidebar-subitem.statement-2 {
  background-image: url(./assets/statement-2.png);
}

.rdp-composer-sidebar-subitem.statement-3 {
  background-image: url(./assets/statement-3.png);
}

.rdp-composer-sidebar-subitem.statement-4 {
  background-image: url(./assets/statement-4.png);
}

.rdp-composer-sidebar-subitem.note-1 {
  background-image: url(./assets/note-1.png);
}

.rdp-composer-sidebar-subitem.quote-1 {
  background-image: url(./assets/quote-1.png);
}

.rdp-composer-sidebar-subitem.quote-2 {
  background-image: url(./assets/quote-2.png);
}

.rdp-composer-sidebar-subitem.quote-3 {
  background-image: url(./assets/quote-3.png);
}

.rdp-composer-sidebar-subitem.quote-4 {
  background-image: url(./assets/quote-4.png);
}

.rdp-composer-sidebar-subitem.quote-5 {
  background-image: url(./assets/quote-5.png);
}

.rdp-composer-sidebar-subitem.quote-6 {
  background-image: url(./assets/quote-6.png);
}

.rdp-composer-sidebar-subitem.list-1 {
  background-image: url(./assets/list-1.png);
}

.rdp-composer-sidebar-subitem.list-2 {
  background-image: url(./assets/list-2.png);
}

.rdp-composer-sidebar-subitem.list-3 {
  background-image: url(./assets/list-3.png);
}

.rdp-composer-sidebar-subitem.image-1 {
  background-image: url(./assets/image-1.png);
}

.rdp-composer-sidebar-subitem.image-2 {
  background-image: url(./assets/image-2.png);
}

.rdp-composer-sidebar-subitem.image-3 {
  background-image: url(./assets/image-3.png);
}

.rdp-composer-sidebar-subitem.image-4 {
  background-image: url(./assets/image-4.png);
}

.rdp-composer-sidebar-subitem.image-and-text {
  background-image: url(./assets/image-and-text.png);
}

.rdp-composer-sidebar-subitem.image-with-text {
  background-image: url(./assets/image-with-text.png);
}

.rdp-composer-sidebar-subitem.gallery-1 {
  background-image: url(./assets/gallery-1.png);
}

.rdp-composer-sidebar-subitem.gallery-2 {
  background-image: url(./assets/gallery-2.png);
}

.rdp-composer-sidebar-subitem.gallery-3 {
  background-image: url(./assets/gallery-3.png);
}

.rdp-composer-sidebar-subitem.gallery-4 {
  background-image: url(./assets/gallery-4.png);
}

.rdp-composer-sidebar-subitem.images-gallery {
  background-image: url(./assets/gallery-4.png);
}

.rdp-composer-sidebar-subitem.audio-1 {
  background-image: url(./assets/audio-1.png);
}

.rdp-composer-sidebar-subitem.video-1 {
  background-image: url(./assets/video-1.png);
}

.rdp-composer-sidebar-subitem.iframe {
  background-image: url(./assets/multimedia-3.png);
}

.rdp-composer-sidebar-subitem.multimedia-4 {
  background-image: url(./assets/multimedia-4.png);
}

.rdp-composer-sidebar-subitem.multimedia-5 {
  background-image: url(./assets/multimedia-5.png);
}

.rdp-composer-sidebar-subitem.accordion {
  background-image: url(./assets/accordion.png);
}

.rdp-composer-sidebar-subitem.tabs {
  background-image: url(./assets/tabs.png);
}

.rdp-composer-sidebar-subitem.interactive-3 {
  background-image: url(./assets/interactive-3.png);
}

.rdp-composer-sidebar-subitem.interactive-4 {
  background-image: url(./assets/interactive-4.png);
}

.rdp-composer-sidebar-subitem.interactive-5 {
  background-image: url(./assets/interactive-5.png);
}

.rdp-composer-sidebar-subitem.interactive-6 {
  background-image: url(./assets/interactive-6.png);
}

.rdp-composer-sidebar-subitem.interactive-7 {
  background-image: url(./assets/interactive-7.png);
}

.rdp-composer-sidebar-subitem.flashcards-1 {
  background-image: url(./assets/flashcards-1.png);
}

.rdp-composer-sidebar-subitem.flashcards-2 {
  background-image: url(./assets/flashcards-2.png);
}

.rdp-composer-sidebar-subitem.interactive-9 {
  background-image: url(./assets/interactive-9.png);
}

.rdp-composer-sidebar-subitem.interactive-10 {
  background-image: url(./assets/interactive-10.png);
}

.rdp-composer-sidebar-subitem.interactive-11 {
  background-image: url(./assets/interactive-11.png);
}

.rdp-composer-sidebar-subitem.quiz-1 {
  background-image: url(./assets/quiz-1.png);
}

.rdp-composer-sidebar-subitem.quiz-2 {
  background-image: url(./assets/quiz-2.png);
}

.rdp-composer-sidebar-subitem.quiz-3 {
  background-image: url(./assets/quiz-3.png);
}

.rdp-composer-sidebar-subitem.quiz-4 {
  background-image: url(./assets/quiz-4.png);
}

.rdp-composer-sidebar-subitem.quiz-5 {
  background-image: url(./assets/quiz-5.png);
}

.rdp-composer-sidebar-subitem.quiz-6 {
  background-image: url(./assets/quiz-6.png);
}

.rdp-composer-sidebar-subitem.chart-1 {
  background-image: url(./assets/chart-1.png);
}

.rdp-composer-sidebar-subitem.chart-2 {
  background-image: url(./assets/chart-2.png);
}

.rdp-composer-sidebar-subitem.chart-3 {
  background-image: url(./assets/chart-3.png);
}

.rdp-composer-sidebar-subitem.divider-1 {
  background-image: url(./assets/divider-1.png);
}

.rdp-composer-sidebar-subitem.divider-numbered {
  background-image: url(./assets/divider-numbered.png);
}

.rdp-composer-sidebar-subitem.divider-3 {
  background-image: url(./assets/divider-3.png);
}

.rdp-composer-sidebar-subitem.divider-4 {
  background-image: url(./assets/divider-4.png);
}

.rdp-composer-sidebar-subitem.forum-1 {
  background-image: url(./assets/forum-1.png);
}

.rdp-composer-sidebar-subitem.virtual-index-1 {
  background-image: url(./assets/marker-1.png);
}

.rdp-composer-sidebar-subitem.hotspots-1 {
  background-image: url(./assets/hotspots-1.png);
}

.rdp-composer-sidebar-subitem.sorted-activity-1 {
  background-image: url(./assets/sorted-activity-1.png);
}

.rdp-composer-sidebar-subitem.timeline-1 {
  background-image: url(./assets/timeline-1.png);
}

.rdp-composer-sidebar-subitem.steps-1 {
  background-image: url(./assets/steps-1.png);
}

.rdp-composer-sidebar-subitem.attachment-1 {
  background-image: url(./assets/attachment-1.png);
}

.rdp-composer-sidebar-subitem.snippet-1 {
  background-image: url(./assets/snippet-1.png);
}

.rdp-composer-sidebar-subitem.button-1 {
  background-image: url(./assets/button-1.png);
}

.rdp-composer-sidebar-subitem.button-2 {
  background-image: url(./assets/button-2.png);
}
.rdp-composer-converter-presets {

}

.rdp-composer-converter-presets-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-converter-presets-elements {
  
}

.rdp-composer-converter-presets-element {
  padding: 10px;
  padding-top: 30px;
  border-bottom: 1px solid #e9e9e9;
}

.rdp-composer-converter-presets-element:nth-child(odd) {
  background-color: #e6e6e6;
}

.rdp-composer-converter-presets-fields {
  width: calc(100% - 270px);
  display: inline-block;
  vertical-align: top;
}

.rdp-composer-converter-presets-field {

}

.rdp-composer-converter-presets-field-label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.rdp-composer-converter-presets-field .rdp-composer-font-picker-select {
  background-color: #FFFFFF;
  height: 40px;
  border-radius: 4px;
}

.rdp-composer-converter-presets-field .rdp-composer-font-picker-select.expanded {
  height: 200px;
}

.rdp-composer-converter-presets-field .rdp-composer-font-picker-select-item {
  height: 40px;
  line-height: 38px;
}

.rdp-composer-converter-presets-field .rdp-composer-font-picker-select-items {
  top: 39px;
}
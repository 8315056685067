.rdp-composer-font-picker-select {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 25px;
  border: 1px solid #d7d7d7;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px; */
  /* border-radius: 4px; */
  position: relative;
  overflow: hidden;
}

.rdp-composer-font-picker-select.expanded {
  height: 200px;
}

.rdp-composer-font-picker-select-active-item {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 48px;
  padding-left: 12px;
  cursor: pointer;
}

.rdp-composer-font-picker-select-items {
  border-top: 1px solid #e0e0e0;
  position: absolute;
  width: 100%;
  top: 50px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-font-picker-select-item {
  display: block;
  padding-left: 12px;
  cursor: pointer;
  height: 50px;
  line-height: 48px;
  border-bottom: 1px solid #f6f6f6;
}

.rdp-composer-font-picker-select-item.bradesco-sans {
  font-family: BradescoSans-Regular;
}

.rdp-composer-font-picker-select-item.lato {
  font-family: Lato-Regular;
}

.rdp-composer-font-picker-select-item.merriweather {
  font-family: Merriweather-Regular;
}

.rdp-composer-font-picker-select-item.open-sans {
  font-family: OpenSans-Regular;
}

.rdp-composer-font-picker-select-item.open-sans-condensed {
  font-family: OpenSansCondensed-Bold;
}

.rdp-composer-font-picker-select-item.prompt {
  font-family: Prompt-Regular;
}

.rdp-composer-font-picker-select-item.product-sans {
  font-family: ProductSans-Regular;
}

.rdp-composer-font-picker-select-item.roboto {
  font-family: Roboto-Regular;
}

.rdp-composer-font-picker-select-item.roboto-condensed {
  font-family: RobotoCondensed-Regular;
}

.rdp-composer-font-picker-select-item.roboto-slab {
  font-family: RobotoSlab-Regular;
}

.rdp-composer-font-picker-select-item.lora {
  font-family: Lora-Regular;
}

.rdp-composer-font-picker-select-item.objective {
  font-family: Objective-Regular;
}

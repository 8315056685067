.color-picker-box-color {
  display: block;
  position: relative;
  border: 1px solid #c9c9c9;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin: 10px 0px 20px 0px;
  padding: 2px;

  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 4px;
  border-radius: 4px;
}
.color-picker-box-color button {
  cursor: pointer;
  display: block;
  float: right;
  background-color: #fff;
  color: #333;
  border-radius: 2px;
  border: none;
  text-transform: uppercase;
  font-size: 11px;
  height: 100%;
  font-weight: bold;
}

.rdp-composer-color-picker .active-color {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 34px;
  padding-left: 8px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
}

.composer-color-picker {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: absolute;
  top: 42px;
  left: 0px;
  z-index: 100;
}

.composer-color-picker > div:first-child {
  padding-bottom: 40% !important;
}

.color-picker-hidden {
  display: none;
  height: 0px;
}

.color-picker-show {
  display: block;
  height: auto;
}

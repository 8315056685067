.rdp-composer-ars-converter {

}

.rdp-composer-ars-converter-header {
  width: 100%;
  background: #ebebeb;
  height: 80px;
  box-shadow: 0px 0px 10px #919191;
  position: absolute;
  top: 0px;
  z-index: 999;
}

.rdp-composer-ars-converter-header-title {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 80px;
  padding-left: 20px;
}

.rdp-composer-ars-converter-header-upload-btn {
  position: absolute;
  padding: 8px 40px 8px 20px;
  top: 24px;
  color: #5d5d5d;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  left: 140px;
  border: 1px solid #CCC;
  border-radius: 110px;
  background-image: url("./icon-file.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px);
}

.rdp-composer-ars-converter-header-submit-btn {
  position: absolute;
  right: 200px;
  padding: 13px 25px;
  top: 20px;
  background: #2d7b45;
  color: #FFF;
  text-transform: uppercase;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

.rdp-composer-ars-converter-header-upload-input {
  display: none;
}

.rdp-composer-ars-converter-sidebar {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0px;
  width: 550px;
  border-right: 1px solid #e9e9e9;
  background: #f7f7f7;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-sidebar-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-sidebar-icon svg {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
  margin-top: 70px;
  opacity: 0.5;
}

.rdp-composer-ars-converter-sidebar-message {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #7c7c7c;
}

.rdp-composer-ars-converter-sidebar ul li {
  /* margin: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 3px #a5a5a5;
  padding: 12px;
  padding-right: 50px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-all; */
}

.rdp-composer-ars-converter-sidebar .rdp-composer-sidebar-subitem {
  box-shadow: 0px 0px 5px #cbcbcb;
  margin-bottom: 0px;
}

.rdp-composer-ars-converter-compositions {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 550px;
  right: -1px;
  border-right: 1px solid #e9e9e9;
  background: #f7f7f7;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-compositions-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-compositions-items {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-compositions-items li {
  position: relative;
  margin: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 3px #a5a5a5;
  padding: 20px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
}

.rdp-composer-ars-converter-compositions-items li.active {
  background: #c5e5cf;
}

.rdp-composer-ars-converter-compositions-item-icon-code {
  width: 54px;
  height: 54px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  background-image: url("./icon-code.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-ars-converter-compositions-actions {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5px;
}

.rdp-composer-ars-converter-compositions-actions-btn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  background-color: #764ab0;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  cursor: pointer;
}

.rdp-composer-ars-converter-compositions-uploading {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000000b8;
}

.rdp-composer-ars-converter-compositions-uploading-modal {
  width: 480px;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 4px;
  padding: 30px;
}

.rdp-composer-ars-converter-compositions-uploading-label,
.rdp-composer-ars-converter-compositions-video-processing-label {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  position: relative;
}

.rdp-composer-ars-converter-compositions-video-processing-label {
  margin-top: 5px;
  font-size: 18px;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-box {
  margin-top: 25px;
  width: 100%;
  height: 60px;
  background: #bbb;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-bar {
  height: 60px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #764ab0;
  transition: all 0.2s linear;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-label {
  width: 100%;
  height: 60px;
  text-align: center;
  display: block;
  line-height: 60px;
  font-weight: bold;
  position: absolute;
  z-index: 1;
  color: #FFF;
  text-shadow: 0px 0px 17px #545454;
}

.rdp-composer-ars-converter-compositions-uploading-message {
  color: #764ab0;
  background: #f1e7ff;
  border-radius: 4px;
  border: 1px solid #c5a4f0;
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
}

.rdp-composer-ars-converter-compositions-uploading-message-alert {
  color: #ff6c00;
  background: #fbedd6;
  border-radius: 4px;
  border: 1px solid #ff9f00;
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px
}

.rdp-composer-ars-converter-compositions-uploading-close-btn {
  display: block;
  margin: auto;
  background: #764ab0;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  border: 0;
  font-size: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
}

.rdp-composer-ars-converter-structure {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #FFF;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-structure-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-structure-header-tab {
  height: 60px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-structure-header-tab.active {
  border-bottom: 4px solid #764ab0;
  color: #764ab0;
  height: 59px;
}

.rdp-composer-ars-converter-structure-header-close {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  cursor: pointer;
  background-image: url("./icon-close.svg");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}

.rdp-composer-ars-converter-structure-json {
  padding: 15px;
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-waiting-bar {
  margin: 10px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdp-composer-ars-converter-waiting-bar .rdp-composer-ars-converter-waiting-bar-bar {
  height: 40px;
  border-radius: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.rdp-composer-ars-converter-waiting-bar .rdp-composer-ars-converter-waiting-bar-bar::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 3000px;
  background-color: #bbb;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 40px,
    #764ab0 40px,
    #764ab0 80px
  );

  -webkit-animation: slide 1s linear infinite;

          animation: slide 1s linear infinite;
  will-change: background-position;
}

@-webkit-keyframes slide {
  from {
    background-position-x: -100px;
  }
  to {
    background-position-x: 0;
  }
}

@keyframes slide {
  from {
    background-position-x: -100px;
  }
  to {
    background-position-x: 0;
  }
}

/*region USER*/
.rdp-composer-header-active-user {
  position: absolute;
  top: 0;
  right: 0;
  background: #ebebeb;
  height: 79px;
  z-index: 1;
  width: 180px;
  cursor: pointer;
  border-left: 1px solid rgba(187, 187, 187, 0.45);
}

.rdp-composer-header-active-user-avatar {
  width: 36px;
  height: 36px;
  background-image: url("./avatar.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 36px;
  position: absolute;
  top: 22px;
  left: 16px;
  border-radius: 100px;
  cursor: pointer;
}

.rdp-composer-header-active-user-hello {
  position: absolute;
  left: 60px;
  top: 30px;
  color: #999;
  font-size: 9px;
  text-transform: uppercase;
}

.rdp-composer-header-active-user-name {
  position: absolute;
  left: 60px;
  top: 42px;
  color: #000;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  max-width: 120px;
}

.rdp-composer-header-active-user-name::after {
  content: "";
  background-image: url("./icon-down.svg");
  background-size: 100%;
  width: 11px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-position: 0 1px;
}

.rdp-composer-header-active-user-name.open::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.rdp-composer-header-user-dropdown-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.rdp-composer-header-user-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-header-user-dropdown-popup {
  position: fixed;
  width: 180px;
  height: 55px;
  background: #ebebeb;
  border-radius: 2px;
  box-shadow: 5px 20px 20px rgb(0 0 0 / 20%);
  top: 80px;
  right: 0;
  z-index: 1;
  overflow: hidden;
  border-left: 1px solid rgba(187, 187, 187, 0.45);
}

.rdp-composer-header-user-dropdown-user {
  padding: 15px;
  border-bottom: 2px solid #efefef;
}

.rdp-composer-header-user-dropdown-user-avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("./avatar.png");
  background-size: 100%;
  vertical-align: middle;
  border-radius: 100px;
}

.rdp-composer-header-user-dropdown-user-name {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.rdp-composer-header-user-dropdown-signout {
  display: block;
  height: 60px;
  line-height: 55px;
  padding-left: 16px;
  font-size: 10px;
  color: #747474;
  cursor: pointer;
  transition: 0.2s all linear;
  text-transform: uppercase;
}

.rdp-composer-header-user-dropdown-signout:hover {
  background: #efefef;
  color: #222;
}
/*endregion USER*/

/*region PROJECT*/
.rdp-composer-header-active-project {
  position: absolute;
  width: 180px;
  top: 0;
  right: 180px;
  height: 79px;
  background: #ebebeb;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;
  border-left: 1px solid rgba(187, 187, 187, 0.45);
}

.rdp-composer-header-active-project-label {
  position: absolute;
  color: #999;
  font-size: 9px;
  text-transform: uppercase;
}

.rdp-composer-header-active-project-name {
  color: #000;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  max-width: 120px;
  margin-top: 13px;
  display: inline-block;
}

.rdp-composer-header-active-project-name::after {
  content: "";
  background-image: url("./icon-down.svg");
  background-size: 100%;
  width: 11px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-position: 0 1px;
}

.rdp-composer-header-active-project-name.open::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.rdp-composer-header-projects-dropdown-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.rdp-composer-header-projects-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-header-projects-dropdown-popup {
  position: fixed;
  width: 180px;
  height: auto;
  background: #ebebeb;
  border-radius: 2px;
  box-shadow: 5px 20px 20px rgb(0 0 0 / 20%);
  top: 80px;
  right: 180px;
  z-index: 1;
  overflow: hidden;
  padding: 5px 0px;
  border-left: 1px solid rgba(187, 187, 187, 0.45);
  border-right: 1px solid rgba(187, 187, 187, 0.45);
}

.rdp-composer-header-projects-dropdown-popup-name {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-size: 10px;
  color: #727272;
  border-bottom: 1px solid #f6f6f6;
  cursor: pointer;
  transition: 0.2s all linear;
  text-transform: uppercase;
}

.rdp-composer-header-projects-dropdown-popup-name.active {
  pointer-events: none;
  background-image: url("./icon-check.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.rdp-composer-header-projects-dropdown-popup-name:last-child {
  border-bottom: 0;
}

.rdp-composer-header-projects-dropdown-popup-name:hover {
  background: #efefef;
}
/*endregion PROJECT*/

/*region Dropzone*/
.rdp-composer-ars-converter-dropzone-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdp-composer-ars-converter-dropzone {
  height: 100%;
  max-height: 300px;
  width: 100%;
  max-width: 600px;
  border: 1px dashed grey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  box-shadow: inset 0 0 5px grey;
}

.rdp-composer-ars-converter-dropzone:hover {
  border: 1px dashed black;
  background-color: #e6e6e6;
}

.rdp-composer-ars-converter-dropzone-container .rdp-composer-ars-converter-dropzone .rdp-composer-ars-converter-header-upload-btn {
  position: inherit;
}
/*endregion Dropzone*/